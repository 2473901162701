@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
    scroll-behavior: smooth;
    user-select: none;
}

/* Hide scrollbar */
.hide-scrollbar {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer and Microsoft Edge */
}

.hide-scrollbar::-webkit-scrollbar {
    width: 0.5rem;
    /* Adjust as needed */
    background: transparent;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
    background: transparent;
}

.active {
    color: #2463EB;
    transition: all ease-in-out 300;
}